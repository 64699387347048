/**
 * A collection of all country flags in SVG.
 * (https://github.com/lipis/flag-icon-css)
 */
/* eslint-disable css-modules/no-unused-class */
import React from 'react';

import { compose } from 'redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './Flag.scss';

const Flag = ({ country, className }) => (
  <span
    role="presentation"
    className={cx(
      className,
      s['flag-icon'],
      s[`flag-icon-${country.toLowerCase()}`],
    )}
  />
);

Flag.propTypes = {
  country: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Flag.defaultProps = {
  className: undefined,
};

export default compose(withStyles(s))(Flag);

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--font-family: 'Poppins', serif;--line-height-body: 1.43;--line-height-heading: 1.2;--font-size-xl: 27px;--font-size-lg: 24px;--font-size-md: 21px;--font-size-sm: 16px;--font-size-xs: 10px;--font-size-heading: 15px;--font-size-input: 16px;--font-size-body: 14px;--font-size-subheading: 13px;--font-size-caption: 12px;--max-content-width: 1408px;--header-height: 80px;--footer-height: 70px;--sidebar-width: 190px;--topBar-height: 80px;--serviceMessage-height: 56px;--redeemOverview-height: 50px;--gutter-width: 8px;--screen-xs-min: 320px;--screen-sm-min: 768px;--screen-md-min: 1024px;--screen-lg-min: 1440px;--body-color: #2b3c47;--body-subdued-color: #808080;--accent-color: #00a1df;--highlight-color: #ae9360;--midnight-color: #2b3c47;--beachy-color: #e2e0d9;--ocean-blue-color: #0076a4;--pale-sky-blue-color: #bfe6f5;--gogiftblue: #009ddb;--grey-blue: #69828f;--dark: #2b3c47;--dark-tan: #b18e48;--dark-beige: #ae9360;--barbie-pink: #fb48c4;--black: #000;--egg-shell: #e2e0d9;--white: #fff;--cloudy-blue: #b1c3d3;--pale-grey: #f1f1f2;--leafy-green: #4fb83b;--light-sage: #bbe5b3;--golden: #eec200;--manilla: #ffea8a;--orangey-red: #de3618;--pale-salmon: #fead9a;--cerulean: #007ace;--powder-blue: #b4e0fa;--ocean-blue: #0076a4;--pale-sky-blue: #bfe6f5;--beige: #c5c3be;--azure: #00a1df;--grey-color: #dfe3e8;--darken: linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));--radial-blue: radial-gradient(at 0% 0%, #126c90 0%, #062c40 100%);--box-shadow-base: 0 10px 3px -3px rgba(0, 0, 0, 0.2)}@media all and (min-width: 1024px){:root{--gutter-width: 16px;--font-size-input: inherit}}@media all and (min-width: 1440px){:root{--sidebar-width: 303px;--gutter-width: 24px;--font-size-input: 16px}}.zLE8L{position:relative;display:inline-block;width:44px;height:44px;margin-left:8px}.zLE8L svg{position:absolute;top:0;left:0;width:100%;height:100%}@media all and (min-width: 1024px){.zLE8L{width:60px;height:60px;margin-right:8px;margin-left:0}}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "zLE8L"
};
module.exports = exports;

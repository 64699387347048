import React from 'react';
import { useMediaQuery } from 'react-responsive';

const isTouchCapable = () =>
  process.env.BROWSER &&
  ('ontouchstart' in window ||
    (window.DocumentTouch && document instanceof window.DocumentTouch) ||
    navigator.maxTouchPoints > 0 ||
    window.navigator.msMaxTouchPoints > 0);

const withResponsiveWrapper = (minWidth = 1024) => WrappedComponent => {
  return props => {
    const isClient = typeof window !== 'undefined';
    const isDesktop = useMediaQuery({
      minWidth,
    });
    // Small screen / tablet  - according to screen sizes in variables.scss
    const isSmallScreen = useMediaQuery({
      minWidth: 768,
      maxWidth: 1023,
    });
    return (
      <WrappedComponent
        {...props}
        isClient={isClient}
        isDesktop={isClient ? isDesktop : undefined}
        isMobile={isClient ? !isDesktop : undefined}
        isSmallScreen={isSmallScreen}
        isTouchCapable={isTouchCapable()}
      />
    );
  };
};
export default withResponsiveWrapper;

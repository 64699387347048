/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from '../Select';
import Flag from '../Flag';

const CountrySwitcher = ({
  countries,
  value,
  defaultValue,
  onChange,
  basic,
  label,
  ...props
}) => (
  <Select
    showLabel={basic}
    label={label}
    left={props => <Flag country={value || defaultValue} {...props} />}
    roundCorners={!basic}
    tertiary={!basic}
    basic={basic}
    value={value || defaultValue}
    options={countries}
    onChange={({ target: { value } }) => onChange(value)}
    {...props}
  />
);

CountrySwitcher.propTypes = {
  value: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  basic: PropTypes.bool,
};

CountrySwitcher.defaultProps = {
  basic: PropTypes.bool,
};

const mapState = state => ({
  countries: state.config.countries,
  defaultValue: state.intl.countryCode,
});

export default connect(mapState)(CountrySwitcher);

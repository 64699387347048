import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import history from '../../history';
import { urlPrefix } from '../../helpers';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

class Link extends React.Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node,
    onClick: PropTypes.func,
    locale: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    unprefixed: PropTypes.bool,
    dispatch: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    onClick: null,
    children: null,
    unprefixed: false,
    disabled: false,
    dispatch: () => {},
  };

  handleClick = event => {
    if (this.props.onClick) {
      this.props.onClick(event);
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented === true) {
      return;
    }

    event.preventDefault();
    const {
      to,
      unprefixed,
      locale,
      countryCode,
      currency,
      disabled,
    } = this.props;
    if (!disabled) {
      history.push(
        unprefixed ? to : urlPrefix(to, { countryCode, locale, currency }),
      );
    }
  };

  render() {
    const {
      to,
      children,
      unprefixed,
      locale,
      countryCode,
      currency,
      dispatch,
      ...props
    } = this.props;
    return (
      <a
        href={
          unprefixed ? to : urlPrefix(to, { countryCode, locale, currency })
        }
        {...props}
        onClick={this.handleClick}
      >
        {children}
      </a>
    );
  }
}

const mapState = state => ({
  locale: state.intl.locale,
  countryCode: state.intl.countryCode,
  currency: state.intl.currency,
});

export default connect(mapState)(Link);

import React from 'react';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';

import toastCss from 'react-toastify/dist/ReactToastify.css';
import withStyles from 'isomorphic-style-loader/withStyles';
import Icon from '../Icon';
import s from './Notifications.scss';
import { ICONS } from '../../constants';

const Notifications = props => (
  <ToastContainer
    autoClose={props.autoClose || 2000}
    draggablePercent={60}
    closeButton={<Icon name={ICONS.CLOSE} size={12} />}
    {...props}
  />
);

Notifications.propTypes = {
  // eslint-disable-next-line react/require-default-props
  autoClose: PropTypes.shape(),
};

export default withStyles(toastCss, s)(Notifications);

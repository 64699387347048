/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './Select.scss';

const Select = ({
  label,
  showLabel,
  options,
  onChange,
  fluid,
  roundCorners,
  basic,
  tertiary,
  left: Left,
  iconRight,
  right: Right,
  value,
  className,
  help,
  mobile,
  disabled,
  required,
  labelRootClass,
  selectRootClass,
  selectClass,
}) => {
  const classes = cx(
    s.wrapper,
    fluid && s.fluid,
    roundCorners && s.roundCorners,
    basic && s.basic,
    mobile && s.mobile,
    tertiary && s.tertiary,
    Left && s.hasIconLeft,
    selectRootClass || '',
  );
  const labelClass = cx(
    s.label,
    !showLabel && 'visually-hidden',
    showLabel === 'inline' ? s.labelInline : s.labelTop,
    required && s.required,
    className || '',
  );
  return (
    <label className={cx(labelRootClass || '', s.root, fluid && s.fluid)}>
      <span className={labelClass}>{label}</span>
      {help && <span className={s.help}>{help}</span>}
      <div className={classes}>
        {Left && <Left className={cx(s.icon, s.iconLeft)} />}
        {iconRight ? (
          <Right className={cx(s.icon, s.iconRight)} />
        ) : (
          <svg width="10" height="5" className={cx(s.icon, s.iconRight)}>
            <path d="M0 0h10L5 5z" />
          </svg>
        )}
        <select
          className={cx(selectClass || '')}
          disabled={disabled}
          onChange={onChange}
          value={value ? value.toString() : ''}
        >
          {options.map(option => (
            <option
              key={option.value.toString()}
              value={option.value.toString()}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </label>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.node,
  className: PropTypes.string,
  help: PropTypes.string,
  fluid: PropTypes.bool,
  roundCorners: PropTypes.bool,
  basic: PropTypes.bool,
  tertiary: PropTypes.bool,
  showLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  left: PropTypes.func,
  right: PropTypes.func,
  iconRight: PropTypes.bool,
  mobile: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  labelRootClass: PropTypes.string,
  selectRootClass: PropTypes.string,
  selectClass: PropTypes.string,
};

Select.defaultProps = {
  value: undefined,
  label: undefined,
  fluid: false,
  roundCorners: false,
  basic: false,
  tertiary: false,
  left: undefined,
  right: undefined,
  iconRight: false,
  showLabel: false,
  mobile: false,
  className: undefined,
  help: undefined,
  required: false,
  disabled: false,
  labelRootClass: '',
  selectRootClass: '',
  selectClass: '',
};

export default withStyles(s)(Select);
